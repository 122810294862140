<template>
    <transition
        enter-active-class="ease-out duration-300"
        enter-class="opacity-0"
        enter-to-class="opacity-100"
        leave-active-class="ease-in duration-200"
        leave-class="opacity-100"
        leave-to-class="opacity-0"
    >
        <div
            v-show="$attrs.modalform"
            class="relative z-10"
            aria-labelledby="modal-title"
            role="dialog"
            aria-modal="true"
        >
            <div
                class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
            ></div>

            <div class="fixed inset-0 z-10 overflow-y-auto">
                <div
                    class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0"
                >
                    <transition
                        enter-active-class="ease-out duration-300"
                        enter-class="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        enter-to-class="opacity-100 translate-y-0 sm:scale-100"
                        leave-active-class="ease-in duration-200"
                        leave-class="opacity-100 translate-y-0 sm:scale-100"
                        leave-to-class="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    >
                        <div
                            v-show="$attrs.modalform"
                            class="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg"
                        >
                            <div class="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                                <div class="flex flex-wrap items-center">
                                    <img
                                        :src="
                                            $attrs.selectedbrand?.media[0]
                                                .original_url
                                        "
                                        style="width: 50%; margin-left: 115px"
                                    />
                                </div>

                                <div
                                    class="flex flex-wrap justify-center items-center"
                                >
                                    <a
                                        v-for="(
                                            item, index
                                        ) of $attrs.selectedagency"
                                        :key="index"
                                        :href="item.url"
                                        target="_blank"
                                        class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full capitalize m-4"
                                    >
                                        <div class="grid">
                                            <div align="center">
                                                <svg
                                                    style="
                                                        stroke: black;
                                                        fill: white;
                                                    "
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    height="15"
                                                    viewBox="0 -960 960 960"
                                                    width="15"
                                                >
                                                    <path
                                                        d="M480.089-490Q509-490 529.5-510.589q20.5-20.588 20.5-49.5Q550-589 529.411-609.5q-20.588-20.5-49.5-20.5Q451-630 430.5-609.411q-20.5 20.588-20.5 49.5Q410-531 430.589-510.5q20.588 20.5 49.5 20.5ZM480-159q133-121 196.5-219.5T740-552q0-117.79-75.292-192.895Q589.417-820 480-820t-184.708 75.105Q220-669.79 220-552q0 75 65 173.5T480-159Zm0 79Q319-217 239.5-334.5T160-552q0-150 96.5-239T480-880q127 0 223.5 89T800-552q0 100-79.5 217.5T480-80Zm0-472Z"
                                                    />
                                                </svg>
                                            </div>
                                            <div>
                                                {{ item.name }}
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            </div>

                            <div
                                class="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6"
                            >
                                <button
                                    @click="hide()"
                                    type="button"
                                    class="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                                >
                                    Cerrar
                                </button>
                            </div>
                        </div>
                    </transition>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
export default {
    data: () => {
        return {
            name: "",
            lastname: "",
            email: "",
            selectedCar: "Autos",
            comment: "",
        };
    },
    methods: {
        hide() {
            this.$attrs.onHidemodal();
        },
    },
    props: {
        starred: {
            type: Boolean,
            default: false,
        },
    },
};
</script>

<style scoped>
.starred path {
    fill: #fff;
}
</style>
