export const agenciesMarkers = [
    {
        brandId: 1, // '1' is the brandId for 'HYUNDAI'
        name: "HYUNDAI",
        agencies: [
            {
                id: 1, 
                name: "HYUNDAI GONZÁLEZ GALLO",
                position: {
                    lat: 20.651950,
                    lng: -103.337140,
                }
            },
            {
                id: 8, 
                name: "HYUNDAI SOLANA HERMOSILLO",
                position: {
                    lat: 29.085350,
                    lng: -110.986070,
                }
            },
            {
                id: 9, 
                name: "HYUNDAI SOLANA OBREGÓN",
                position: {
                    lat: 27.465390,
                    lng: -109.932910,
                }
            },
            {
                id: 10, 
                name: "HYUNDAI SOLANA CAMELINAS",
                position: {
                    lat: 19.684150,
                    lng: -101.165730,
                }
            },
            {
                id: 11, 
                name: "HYUNDAI SOLANA URUAPAN",
                position: {
                    lat: 19.408190,
                    lng: -102.053700,
                }
            },

        ],
    },
    {
        brandId: 2,
        name: "JAC",
        agencies: [
            {
                id: 2, 
                name: "JAC SOLANA GUADALAJARA",
                position: {
                    lat: 20.624290,
                    lng: -103.420570,
                }
            },
            {
                id: 15, 
                name: "JAC SOLANA HERMOSILLO",
                position: {
                    lat: 29.049740,
                    lng: -110.969400,
                }
            },
            {
                id: 16, 
                name: "JAC SOLANA GALERIAS",
                position: {
                    lat: 20.705970,
                    lng: -103.463700,
                }
            },
            {
                id: 17, 
                name: "JAC PUERTO VALLARTA",
                position: {
                    lat: 20.691580,
                    lng: -105.255090,
                }
            },
        ],
    },
    {
        brandId: 3,
        name: "SUZUKI",
        agencies: [
            {
                id: 3, 
                name: "SUZUKI SOLANA GONZÁLEZ GALLO",
                position: {
                    lat: 20.582430,
                    lng: -103.386760,
                }
            },
            {
                id: 18, 
                name: "SUZUKI SOLANA COUNTRY",
                position: {
                    lat: 20.697250,
                    lng: -103.368170,
                }
            },
            {
                id: 19, 
                name: "SUZUKI SOLANA OBREGÓN",
                position: {
                    lat: 29.113850,
                    lng: -110.950940,
                }
            },
            {
                id: 20, 
                name: "SUZUKI SOLANA HERMOSILLO",
                position: {
                    lat: 29.118270,
                    lng: -110.971440,
                }
            },
            {
                id: 21, 
                name: "SUZUKI SANTA ANITA",
                position: {
                    lat: 20.574660,
                    lng: -103.453070,
                }
            },
            {
                id: 34,  
                name: "SUZUKI MOCHIS",
                position: {
                    lat:  25.787326781137395,
                    lng: -108.99573441109465,
                }
            },
        ],
    },
    {
        brandId: 4,
        name: "TOYOTA",
        agencies: [
            {
                id: 4, 
                name: "TOYOTA SOLANA INTERLOMAS",
                position: {
                    lat: 19.428130,
                    lng: -99.242490,
                }
            },
            {
                id: 22, 
                name: "TOYOTA SOLANA NOGALES",
                position: {
                    lat: 31.292150,
                    lng: -110.946140,
                }
            },
            {
                id: 23, 
                name: "TOYOTA SOLANA HERMOSILLO",
                position: {
                    lat: 29.070240,
                    lng: -110.959230,
                }
            },
            {
                id: 24, 
                name: "TOYOTA SOLANA OBREGÓN",
                position: {
                    lat: 27.465390,
                    lng: -109.932910,
                }
            },
            {
                id: 25, 
                name: "TOYOTA SOLANA MORELOS",
                position: {
                    lat: 29.113850,
                    lng: -110.950940,
                }
            },
        ],
    },
    {
        brandId: 5,
        name: "ISUZU",
        agencies: [
            {
                id: 5, 
                name: "ISUZU SOLANA GUADALAJARA",
                position: {
                    lat: 20.714470,
                    lng: -103.471760,
                }
            },
            {
                id: 26, 
                name: "ISUZU SOLANA HERMOSILLO",
                position: {
                    lat: 29.035870,
                    lng: -110.952010,
                }
            },
        ],
    },
    {
        brandId: 6,
        name: "KIA",
        agencies: [
            {
                id: 6, 
                name: "KIA SANTA FE",
                position: {
                    lat: 19.367120,
                    lng: -99.266740,
                }
            },
            {
                id: 27, 
                name: "KIA KONFIDENCE SANTA FE",
                position: {
                    lat: 19.378210,
                    lng: -99.248850,
                }
            },
            {
                id: 35, 
                name: "KIA ESMERALDA",
                position: {
                    lat: 19.551987744907713,
                    lng: -99.28829554700289,
                }
            },
        ],
    },
    {
        brandId: 7, // '1' is the brandId for 'HYUNDAI'
        name: "VOLKSWAGEN",
        agencies: [
            {
                id: 7, 
                name: "VOLKSWAGEN SOLANA VADO DEL RÍO",
                position: { 
                    lat: 29.069300,
                    lng: -110.940170,
                }
            },
        ],
    },
    {
        brandId: 8,
        name: "GEELY",
        agencies: [
            {
                id: 12, 
                name: "GEELY TEPEPAN",
                position: {
                    lat: 19.285640,
                    lng: -99.147740,
                }
            },
            {
                id: 28, 
                name: "GEELY COUNTRY",
                position: {
                    lat: 20.699810,
                    lng: -103.367360,
                }
            },
            {
                id: 29, 
                name: "GEELY LOPEZ MATEOS",
                position: {
                    lat: 20.627960,
                    lng: -103.417850,
                }
            },
            {
                id: 36, 
                name: "GEELY PUERTO VALLARTA",
                position: {  
                    lat: 20.694088922274656,
                    lng: -105.24542106802309,
                }
            },
        ],
    },
    {
        brandId: 9,
        name: "GAC MOTOR",
        agencies: [
            { 
                id: 14, 
                name: "GAC HERMOSILLO",
                position: {
                    lat: 29.06936792629787,
                    lng: -110.95185851782837,
                }
            },
            { 
                id: 14, 
                name: "GAC MORELIA",
                position: { 
                    lat: 20.014001347670742,
                    lng: -101.19749281857871,
                }
            },
            { 
                id: 33,  
                name: "GAC CULIACAN",
                position: { 
                    lat: 19.729732938536117,
                    lng: -101.00156196605353,
                }
            },

        ]
    },
    {
        brandId: 10,
        name: "GWM",
        agencies: [
            {
                id: 13, 
                name: "GWM ACUEDUCTO",
                position: {
                    lat: 20.708993,
                    lng: -103.407056,
                }
            },
            {
                id: 30,  
                name: "GWM HERMOSILLO",
                position: {
                    lat: 29.069226158551636,
                    lng: -110.9642133434868,
                }
            },
            {
                id: 37,  
                name: "GWM PUERTO VALLARTA",
                position: {
                    lat: 20.789153004576804,
                    lng: -105.21634743183448,
                }
            },
            {
                id: 31,  
                name: "GWM AV VALLARTA",
                position: {
                    lat: 20.682859678718593,
                    lng: -103.4372767707755,
                }
            },
        ]
    },
];