export const apiUrl = "./api/";
export const images = [
    "./images/slider/corporativo.png",
    "./images/slider/1.png",
    "./images/slider/2.png",
    "./images/slider/3.jpg",
    "./images/slider/4_n.png",
    "./images/slider/5.png",
    "./images/slider/6.jpg",
    "./images/slider/7_n.jpg",
    "./images/slider/promocion1.png",
    "./images/slider/promocion2.png",
    "./images/slider/promocion3.jpg",
];
