import { agencies, agenciesNames } from "./constants/agency";

export const mixins = {
    data() {
        return {
            index: 0,
            modalForm: false,
            modalBanner: false,
            modalFormSus: false,
            modalFormSuserr: false,
            selectedBrand: null,
            selectedAgencies: null,
            product: {},
        };
    },
    mounted() {},
    methods: {
        submitCatalog(e) {
            e.preventDefault();
            let data = {
                _token: this.$refs.catalogForm._token.value,
                recaptcha_token: this.$refs.catalogForm.recaptcha_token.value,
                catalog_id: this.$refs.catalogForm.catalog_id.value,
                catalog_url: this.$refs.catalogForm.catalog_url.value,
                name: this.$refs.catalogForm.name.value,
                email: this.$refs.catalogForm.email.value,
            };

            if (this.validateForms(data)) {
                this.postData("/catalogo", data).then((res) => {
                    if (res === 200) {
                        this.closeCatalog();
                        window.open(data.catalog_url, "_blank");
                    } else {
                        this.$toasted.show(
                            "Ocurrio un error, por favor intente mas tarde.",
                            { type: "error" }
                        );
                    }
                });
            }
        },
        validateForms(data) {
            let keys = Object.keys(data);
            let flag = true;
            for (let index = 0; index < keys.length; index++) {
                if (keys[index] !== "recaptcha_token") {
                    if (data[keys[index]] === "") {
                        // console.log(keys[index]);
                        let dictionary = {
                            name: "nombre",
                            email: "correo",
                        };
                        this.$toasted.show(
                            `Por favor agregue el campo de ${
                                dictionary[keys[index]]
                            } para continuar.`,
                            { type: "error" }
                        );
                        flag = false;
                        return flag;
                    }
                }
            }
            return flag;
        },
        showModal() {
            this.modalForm = !this.modalForm;
        },
        showModalBanner(banner) {
            if (banner) {
                this.selectedAgencies = banner.agencies;
                this.selectedBrand = banner.brand;
                this.modalBanner = !this.modalBanner;
            }
        },
        hideModalBanner() {
            this.modalBanner = !this.modalBanner;
        },
        showModalSus() {
            /*axios.post("./api/floats-contact", request).then((response) => {
                if (response.status === 200) {
                    alert(response.data)
                    this.hide();
                }
            });*/
            var suscribeteemail =
                document.getElementById("suscribeteemail").value;

            if (suscribeteemail != "") {
                this.modalFormSus = !this.modalFormSus;
            } else {
                this.modalFormSuserr = !this.modalFormSuserr;
            }
        },
        scrollToTop() {
            window.scrollTo(0, 0);
        },
    },
};
