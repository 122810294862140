<template>
    <transition
        enter-active-class="ease-out duration-300"
        enter-class="opacity-0"
        enter-to-class="opacity-100"
        leave-active-class="ease-in duration-200"
        leave-class="opacity-100"
        leave-to-class="opacity-0"
    >
        <div
            v-show="$attrs.modalform"
            class="relative z-10"
            aria-labelledby="modal-title"
            role="dialog"
            aria-modal="true"
        >
            <div
                class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
            ></div>

            <div class="fixed inset-0 z-10 overflow-y-auto">
                <div
                    class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0"
                >
                    <transition
                        enter-active-class="ease-out duration-300"
                        enter-class="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        enter-to-class="opacity-100 translate-y-0 sm:scale-100"
                        leave-active-class="ease-in duration-200"
                        leave-class="opacity-100 translate-y-0 sm:scale-100"
                        leave-to-class="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    >
                        <div
                            v-show="$attrs.modalform"
                            class="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg"
                        >
                            <div class="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                                <form class="w-full max-w-lg">
                                    <div class="flex flex-wrap -mx-3 mb-2">
                                        <div
                                            class="w-full md:w-1/2 px-3 mb-6 md:mb-0"
                                        >
                                            <label
                                                class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                                                for="grid-first-name"
                                            >
                                                Nombre(s)
                                            </label>
                                            <input
                                                class="appearance-none block w-full text-gray-900 bg-gray-50 rounded-lg border border-gray-300 py-3 px-4 leading-tight focus:ring-blue-500 focus:border-blue-500 focus:bg-white"
                                                id="grid-first-name"
                                                type="text"
                                                v-model="name"
                                                placeholder="Nombre"
                                            />
                                        </div>
                                        <div class="w-full md:w-1/2 px-3">
                                            <label
                                                class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                                                for="grid-last-name"
                                            >
                                                Apellidos
                                            </label>
                                            <input
                                                class="appearance-none block w-full text-gray-900 bg-gray-50 rounded-lg border border-gray-300 py-3 px-4 leading-tight focus:ring-blue-500 focus:border-blue-500 focus:bg-white"
                                                id="grid-last-name"
                                                type="text"
                                                v-model="lastname"
                                                placeholder="Apellido"
                                            />
                                        </div>
                                    </div>
                                    <div class="flex flex-wrap -mx-3 mb-2">
                                        <div class="w-full px-3 mb-6 md:mb-2">
                                            <label
                                                class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                                                for="grid-city"
                                            >
                                                Correo
                                            </label>
                                            <input
                                                class="appearance-none block w-full text-gray-900 bg-gray-50 rounded-lg border border-gray-300 py-3 px-4 leading-tight focus:ring-blue-500 focus:border-blue-500 focus:bg-white"
                                                id="grid-city"
                                                type="text"
                                                v-model="email"
                                                placeholder="example@example.com"
                                            />
                                        </div>
                                        <div class="w-full px-3 mb-6 md:mb-2">
                                            <label
                                                class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                                                for="grid-state"
                                            >
                                                Auto de interes
                                            </label>
                                            <div class="relative">
                                                <select
                                                    v-model="selectedCar"
                                                    class="block appearance-none block w-full text-gray-900 bg-gray-50 rounded-lg border border-gray-300 py-3 px-4 leading-tight focus:ring-blue-500 focus:border-blue-500 focus:bg-white"
                                                    id="grid-state"
                                                >
                                                    <!-- <option disabled value="">Please select one</option> -->
                                                    <option value="Autos">
                                                        Autos
                                                    </option>
                                                    <option value="SUV">
                                                        SUV
                                                    </option>
                                                    <option value="PICK UP">
                                                        PICK UP
                                                    </option>
                                                    <option value="HÍBRIDOS">
                                                        HÍBRIDOS
                                                    </option>
                                                    <option
                                                        value="TRANSPORTE DE PERSONAL"
                                                    >
                                                        TRANSPORTE DE PERSONAL
                                                    </option>
                                                    <option value="CAMIONEs">
                                                        CAMIONES
                                                    </option>
                                                </select>
                                                <div
                                                    class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"
                                                >
                                                    <svg
                                                        class="fill-current h-4 w-4"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        viewBox="0 0 20 20"
                                                    >
                                                        <path
                                                            d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                                                        />
                                                    </svg>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="w-full px-3 mb-6 md:mb-2">
                                            <label
                                                class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                                                for="message"
                                            >
                                                Comentario
                                            </label>
                                            <textarea
                                                id="message"
                                                rows="4"
                                                v-model="comment"
                                                class="block p-4 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                                placeholder="Deja un comentario..."
                                            ></textarea>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div
                                class="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6"
                            >
                                <button
                                    @click="sendMail()"
                                    type="button"
                                    class="inline-flex w-full justify-center rounded-md bg-primary px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 sm:ml-3 sm:w-auto transition duration-500"
                                >
                                    Enviar
                                </button>
                                <button
                                    @click="hide()"
                                    type="button"
                                    class="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                                >
                                    Cancelar
                                </button>
                            </div>
                        </div>
                    </transition>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
import { mixins } from "../utils";

export default {
    // mixins: [mixins],
    data: () => {
        return {
            name: "",
            lastname: "",
            email: "",
            selectedCar: "Autos",
            comment: "",
        };
    },
    methods: {
        hide() {
            this.$attrs.onHidemodal();
        },
        sendMail() {
            const request = {
                name: this.name,
                lastname: this.lastname,
                email: this.email,
                selectedCar: this.selectedCar,
                comment: this.comment,
            };
            // console.log(request);
            axios.post("./api/floats-contact", request).then((response) => {
                if (response.status === 200) {
                    alert(response.data)
                    this.hide();
                }
            });
        },
    },
};
</script>
