<template>
    <Carousel :autoplay="delay" :wrap-around="true">
        <Slide v-for="(banner, index) of banners" :key="index">
            <div class="carousel__item ">
                <img :src="banner.media[0].original_url" alt="" />
            </div>
        </Slide>

        <template #addons>
            <!-- <Navigation /> -->
            <!-- <Pagination /> -->
        </template>
    </Carousel>
</template>

<script>
import { defineComponent } from "vue";
import { Carousel, Navigation, Pagination, Slide } from "vue3-carousel";

import "vue3-carousel/dist/carousel.css";
import { apiUrl } from "../constants";

export default defineComponent({
    name: "Basic",
    components: {
        Carousel,
        Slide,
        Pagination,
        Navigation,
    },
    props: ['page', 'delay'],
    data() {
        return {
            banners: [],
        };
    },
    mounted() {
        axios.get(apiUrl + "banners").then((response) => {
            const { data: items } = response;
            this.paginate(items.data)
        });
    },
    methods: {
        show(banner) {
            if (banner.brand_id) {
                this.$attrs.onShowmodal(banner);
            }
        },
        paginate(data) {
            const startIndex = (this.page - 1) * 3;
            const endIndex = startIndex + 3;
            const pageItems = data.slice(startIndex, endIndex);

            // Display current page items
            pageItems.forEach(item => {
                this.banners.push(item)
            });
        }
    },
});
</script>

<style>
.carousel__viewport {
    border-radius: 10px;
    box-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
}

.carousel__item {
    width: 100%;
    height: 100%;
    font-size: 20px;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.carousel__item img {
    border-radius: 10px;
    height: 100% !important;
    object-fit: cover;
}

.carousel__slide {
    padding: 0px;
}

.carousel__pagination {
    margin: -40px 0 0;
    z-index: 10;
    position: absolute;
    width: 100%;
}

.carousel__pagination-button::after {
    width: 30px;
}
</style>
