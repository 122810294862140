import "./bootstrap";

import { createApp } from "vue";
import VueGoogleMaps from '@fawmi/vue-google-maps'

window.Vue = require('vue');

import SliderComponent from "./components/SliderComponent";
import ModalComponent from "./components/ModalComponent";
import ModalBanner from "./components/ModalBanner";
import ModalComponentSus from "./components/ModalComponentSus";
import ModalComponentSuserr from "./components/ModalComponentSuserr";
import MapComponent from "./components/MapComponent";
import { mixins } from './utils'


const app = createApp({ mixins: [mixins] });

app.use(VueGoogleMaps, {
    load: {
        key: 'AIzaSyA1VrDF0ZMhx7XdVMI2r-gdhtMAn1BE5hU',
    },
})

app.component("slider-component", SliderComponent);
app.component("modal-component", ModalComponent);
app.component("modal-banner", ModalBanner);
app.component("modal-component-sus", ModalComponentSus);
app.component("modal-component-sus-err", ModalComponentSuserr);
app.component("map-component", MapComponent);

app.mount("#app");
